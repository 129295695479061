import React from "react";
import Layout from "../../components/UI/layout";
import SEO from "../../components/UI/seo";
import FrontDevSection from "../../components/Search/Details/Frontdev/frontdev"
import { graphql } from "gatsby";

const FrontDevPage = () => (    
    <Layout>
        <SEO title="Codeicus | FrontDev" />
        <FrontDevSection/>
    </Layout>
)

export default FrontDevPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;